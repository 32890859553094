import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import thankYouStyles from '../styles/thank-you.module.css';

const ThankYouPage = () => {
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      navigate('/');
      return;
    }
  }, [seconds]);

  return (
    <Layout>
      <SEO title={'Thank You!'} />
      <div className={thankYouStyles.container}>
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <Header />
        </div>
        <h1 className={thankYouStyles.textTitle}>Thank You!</h1>
        <div className={thankYouStyles.containerBody}>
          <span className={thankYouStyles.textBody}>
            Thank you for contributing to Major Mazer!
          </span>
        </div>
        <div style={{ height: '2rem' }} />
        <div className={thankYouStyles.containerBody}>
          <span className={thankYouStyles.textBody}>
            You should be redirected to the home screen in {seconds} seconds. If
            not, click{' '}
            <Link to={'/'} style={{ fontSize: '1.1rem' }}>
              here
            </Link>
            .
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
